<template>
  <div class="secret">
    <van-field v-model="id" />
    <van-button type="primary" @click="handleLogin">确定</van-button>
    <img src="/images/login/cute.GIF">
  </div>
</template>

<script>
import {oauthLogin} from "@/api/login";

export default {
  name: "index",
  data() {
    return {
      id: ''
    }
  },
  methods: {
    handleLogin() {
      if(this.id) {
        oauthLogin({oauthToken: this.id}).then(async res=>{
          this.$store.commit('user/SET_USERINFO', res);
          this.$router.replace("/")
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.secret {
  background-color: #EDEDED;
  height: 100%;
  padding-top: 50px;
  text-align: center;

  img {
    width: 100%;
  }

  .van-field {
    width: 80%;
    margin: 0 auto;
  }
  .van-button {
    margin: 20px 0;
    width: 100px;
  }
}
</style>