
//
import request from "@/utils/request";

// 闪布login
export function oauthLogin(params) {
    return request({
        url: '/api/zeekr-auth/oauth/oauthLogin',
        method: 'get',
        params
    })
}

// 闪布login
export function uuidLogin(params) {
    return request({
        url: '/api/zeekr-auth/oauth/uuidLogin',
        method: 'get',
        params
    })
}


// 微信code换区token
export function wechatLogin(params) {
    return request({
        url: '/api/zeekr-auth/oauth/enterprise/wechat/login',
        method: 'get',
        params
    })
}


// 官方身份切换
export function switchLogin() {
    return request({
        url: '/api/zeekr-auth/switchIdentity',
        method: 'get'
    })
}
